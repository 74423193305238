<template>
  <v-navigation-drawer v-model="localDrawer" app clipped floating>
    <v-list dense class="py-0 dekra-list">
      <template v-for="item in items">
        <v-list-group
          v-if="item.haveChildren && userCan(item.permission)"
          :key="item.title"
          v-model="item.model"
          value="true"
          :color="item.color"
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="body-2 text-left">
                  {{ limitSize(item.title, 17) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="(child, i) in item.children">
            <v-list-item
              :key="i"
              v-if="userCan(child.permission)"
              :to="{ path: child.path }"
              :active-class="'dekra-active ' + item.class"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-left">
                  {{ limitSize(child.title, 17) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <template v-else>
          <v-list-item
            v-if="userCan(item.permission)"
            :key="item.title"
            :color="item.color"
            :to="{ path: item.path }"
            :active-class="'dekra-active ' + item.class"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="body-2 text-left">
                {{ limitSize(item.title, 20) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import string from "@/mixins/string";
import permission from "@/mixins/permission";
export default {
  mixins: [string, permission],
  data() {
    return {
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
          class: "green--text text--darken-3",
          color: "green darken-3",
          path: "/dashboard"
        },
        {
          title: "Workforce Requests",
          permission: "list-workforce",
          icon: "mdi-lan",
          class: "pink--text text--accent-4",
          color: "pink accent-4",
          path: "/workforce"
        },
        {
          title: "Companies",
          permission: "list-company",
          icon: "mdi-briefcase-outline",
          class: "light-blue--text text--darken-1",
          color: "light-blue darken-1",
          path: "/company"
        },
        {
          title: "Employees",
          permission: "list-employee",
          icon: "mdi-account-hard-hat",
          class: "orange--text text--darken-3",
          color: "orange darken-3",
          path: "/employee"
        },
        {
          title: "Candidates",
          permission: "list-candidate",
          icon: "mdi-account-multiple-plus",
          class: "amber--text text--darken-3",
          color: "amber darken-3",
          path: "/candidate"
        },
        {
          title: "Productivity",
          permission: "export-productivity",
          class: "green--text text--darken-3",
          color: "green darken-3",
          icon: "mdi-finance",
          path: "/productivity"
        },
        {
          title: "Reporting",
          permission: "list-report",
          class: "green--text text--darken-3",
          color: "green darken-3",
          icon: "mdi-file-chart",
          path: "/report"
        },
        {
          title: "Files",
          permission: "list-file",
          class: "green--text text--darken-3",
          color: "green darken-3",
          icon: "mdi-file",
          path: "/file"
        },
        {
          title: "Users",
          permission: ["list-role", "list-permission", "list-user"],
          icon: "mdi-account-circle-outline",
          model: false,
          class: "green--text text--darken-3",
          color: "green darken-3",
          haveChildren: true,
          path: "#",
          children: [
            {
              title: "Roles",
              permission: "list-role",
              icon: "mdi-account-supervisor",
              path: "/role"
            },
            {
              title: "Permissions",
              permission: "list-permission",
              icon: "mdi-account-check",
              path: "/permission"
            },
            {
              title: "Users",
              permission: "list-user",
              icon: "mdi-account-circle",
              path: "/user"
            }
          ]
        },
        {
          title: "Settings",
          icon: "mdi-cog-outline",
          permission: [
            "list-city",
            "list-country",
            "list-jobPosition",
            "list-companyType",
            "list-companyStatus",
            "list-workforceType",
            "list-fileType",
            "list-log"
          ],
          model: false,
          class: "green--text text--darken-3",
          color: "green darken-3",
          haveChildren: true,
          path: "#",
          children: [
            {
              title: "Cities",
              permission: "list-city",
              icon: "mdi-city",
              path: "/city"
            },
            {
              title: "Countries",
              permission: "list-country",
              icon: "mdi-map",
              path: "/country"
            },
            {
              title: "Job Positions",
              permission: "list-jobPosition",
              icon: "mdi-account-hard-hat",
              path: "/job-position"
            },
            {
              title: "Company types",
              permission: "list-companyType",
              icon: "mdi-briefcase-upload-outline",
              path: "/company-type"
            },
            {
              title: "Company status",
              permission: "list-companyStatus",
              icon: "mdi-briefcase-account",
              path: "/company-status"
            },
            {
              title: "Workforce types",
              permission: "list-workforceType",
              icon: "mdi-lan",
              path: "/workforce-type"
            },
            {
              title: "File types",
              permission: "list-fileType",
              icon: "mdi-file-word-box",
              path: "/file-type"
            },
            {
              title: "Logs",
              permission: "list-log",
              icon: "mdi-math-log",
              path: "/logs"
            },
            {
              title: "Access logs",
              permission: "list-access-log",
              icon: "mdi-wifi-alert",
              path: "/access-logs"
            }
          ]
        }
      ]
    };
  },
  created() {},
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("drawer-update", value);
      }
    }
  },
  methods: {}
};
</script>
<style scoped>
.v-navigation-drawer .v-list.dekra-list .dekra-active {
  border-left: 5px solid;
  height: 50px;
}
</style>
